<template>
    <validation-provider :name="name" :vid="vid" :rules="rules" v-slot="validationContext">
        <b-form-group :label="label">
            <div class="form-check abc-checkbox abc-checkbox-success form-check-inline">
                <input @change="$emit('input', $event.target.checked)"
                       :value="value"
                       :checked="value"
                       :state="valid(validationContext)"
                       class="form-check-input" type="checkbox" :id="vid">
                <label class="form-check-label" :for="vid">{{name}}</label>
            </div>
            <div v-if="hint"><small class="form-text text-muted">{{hint}}</small></div>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
    </validation-provider>
</template>

<script>
    export default {
        name: "ValidCheckbox",
        props: ['rules', 'name', 'value', 'label', 'vid', 'type', 'hint']
    }
</script>

<style scoped>

</style>
