<template>
  <div class="wrapper">
    <head-component/>
    <section class="block-courses" v-if="post">
      <h1>Блог</h1>
      <div class="course-item">
        <div class="row align-items-center mb-1">
          <div class="col">
            <h2 class="mb-2"><a href="/">{{post.title }}</a></h2>
            <div class="course-item__text" v-html="post.text"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import HeadComponent from "../components/HeadComponent";

export default {
  name: "News",
  components: {HeadComponent},
  data() {
    return {
      post: null
    }
  },
  mounted() {
    axios.get('https://api.old.universarium.org/posts/' + this.$route.params.id, {
      headers: null
    })
    .then(res => {
      this.post = res.data
    })
  }
}
</script>

<style scoped>

</style>