<template>
  <span>
    <b-modal ref="profile" v-if="user" id="profile" class="modal-profile" hide-footer size="lg" title="Редактирование профиля">
        <h4><i class="fa fa-edit"></i> Профиль:</h4>
        <div class="modal-profile">
          <b-form @submit="onChange" @reset="onReset" v-if="show">
            <b-form-group id="input-group-2" label="Имя" label-for="input-2">
              <b-form-input
                  id="input-2"
                  v-model="user.firstName"
                  placeholder="Имя"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-4" label="Отчество" label-for="input-3">
              <b-form-input
                  id="input-3"
                  v-model="user.middleName"
                  placeholder="Отчество"
                  required
              ></b-form-input>
            </b-form-group>

             <b-form-group id="input-group-4" label="Фамилия" label-for="input-4">
              <b-form-input
                  id="input-3"
                  v-model="user.lastName"
                  placeholder="Фамилия"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-1" label="Email" label-for="input-1">
              <b-form-input
                  id="input-1"
                  disabled
                  v-model="user.username"
                  type="email"
                  placeholder="apetrov74@gmail.com"
                  required
              ></b-form-input>
            </b-form-group>


            <div class="row mt-5">
              <div class="col-auto">
                <b-button type="submit" variant="success">Сохранить</b-button>
              </div>
              <div class="col-auto">
                <b-button @click="close()" type="button" variant="outline-dark">Отменить</b-button>
              </div>
            </div>
          </b-form>
        </div>
        <hr/>
        <Settings :subscribe="user.postUserSubscribe" />
    </b-modal>
    <b-modal ref="login" id="login" class="modal-login" hide-footer size="xl">
      <div class="modal-login">
        <div class="row">
          <div class="col-md-6 mb-3 mb-md-0">
            <div class="mr-4">
              <h2>Добро пожаловать в Универсариум!</h2>
              <template v-if="loginAsUni">
                <div class="modal-login__text">
                  <p>Пожалуйста, введите ваш логин (email) или пароль от Универсариума.</p>
                </div>
              </template>
              <template v-else>
                <div class="modal-login__text">
                  <p>Пожалуйста, укажите ваши имя и фамили, логин (email) и пароль.</p>
                  <p>После окончания обучения вы получите именной сертификат.</p>
                </div>
              </template>
            </div>
          </div>
          <div class="col-md-6">
            <template v-if="loginAsUni">
              <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group id="input-group-2" label="Имя" label-for="input-2">
                  <b-form-input
                      id="input-2"
                      v-model="form.name"
                      placeholder=""
                      required
                  ></b-form-input>
                </b-form-group>
                <b-form-group id="input-group-3" label="Фамилия" label-for="input-3">
                  <b-form-input
                      id="input-3"
                      v-model="form.lastname"
                      placeholder=""
                      required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                    id="input-group-1"
                    label="Email"
                    label-for="input-1"
                >
                  <b-form-input
                      id="input-1"
                      v-model="form.username"
                      type="email"
                      placeholder=""
                      required
                  ></b-form-input>
                </b-form-group>
                <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                      v-model="form.checked"
                      id="checkboxes-4"
                      :aria-describedby="ariaDescribedby"
                  >
                    <b-form-checkbox v-model="agreement">Согласен на обработку персональных данных</b-form-checkbox>
                    <b-form-checkbox v-model="agreement">Хочу получать информацию о новых курсах и другие новости</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
                <b-button @click="register" type="submit" variant="success" class="mt-3">Зарегистрироваться</b-button>
                <div class="my-3">или</div>
                <a @click="() => this.loginAsUni = false" class="btn btn-success">Войти через Универсуриум</a>
              </b-form>
            </template>
            <template v-else>
              <b-form @submit="onSubmit" @reset="onReset" v-if="show">

                <b-form-group
                    id="input-group-1"
                    label="Email"
                    label-for="input-1"
                >
                  <b-form-input
                      id="input-1"
                      v-model="form.username"
                      type="email"
                      placeholder=""
                      required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="input-group-1"
                    label="Пароль"
                    label-for="input-1"
                >
                  <b-form-input
                      id="input-1"
                      v-model="form.password"
                      type="password"
                      placeholder=""
                      required
                  ></b-form-input>
                </b-form-group>

                <b-button type="submit" variant="success" class="mt-3">Вход</b-button>
                <div class="my-3">или</div>
                <a @click="register" class="btn btn-success">Зарегистрироваться</a>
              </b-form>
            </template>
          </div>
        </div>
      </div>
    </b-modal>
    <a v-if="!user" v-b-modal.login href="#">Войти</a>
        <b-dropdown v-else id="user-menu" :text="user.fullname">
          <b-dropdown-item v-b-modal.profile href="#">Редактировать профиль</b-dropdown-item>
          <b-dropdown-item href="/certificates">Сертификаты</b-dropdown-item>
          <b-dropdown-item v-if="user.id === 5" href="/admin">Система админиистрирования</b-dropdown-item>
          <b-dropdown-item @click="logout" class="logout">Выйти</b-dropdown-item>
        </b-dropdown>
  </span>
</template>

<script>
import ApiService from "../services/api.service";
import {TokenService} from "../services/token.service";
import {eventBus} from '../main'
import Settings from "./Settings";

export default {
  name: "Auth",
  components: {Settings},
  data() {
    return {
      loginAsUni: false,
      user: null,
      agreement: true,
      form: {
        email: '',
        name: '',
        lastname: '',
        checked: [],
        courses: null
      },
      show: true
    }
  },
  mounted() {
    if(this.$route.query.login) {
      this.$bvModal.show('login')
    }
    this.user = TokenService.getUser()
    eventBus.$on('auth:modal:show', () => {
      this.showModal()
    })
  },
  methods: {
    register() {
      window.location.href = 'https://universarium.org/registration?redirect=isKids'
      //this.loginAsUni = true
    },
    showModal() {
      this.$refs['login'].show()
    },
    close() {
      this.$refs['profile'].hide()
    },
    onSubmit(event) {
      event.preventDefault()
      ApiService.postJSON('login_check', {
        password: this.form.password,
        username: this.form.username
      }).then(res => {
        TokenService.setToken(res.data.token)
        ApiService.setHeader()
        ApiService.get('me').then(res => {
          TokenService.setUser(res.data)
          this.user = res.data
          this.$refs['login'].hide()
          this.onReset()
        })
      })
    },
    onChange() {
      console.log(this.user)
    },
    logout() {
       TokenService.clearUser()
       TokenService.removeToken()
      this.user = null
    },
    onReset() {
      // Reset our form values
      this.form.username = ''
      this.form.name = ''
      this.form.checked = []
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>

<style scoped>

</style>