<template>
  <section class="header">
    <b-modal ref="feedback" id="feedback" class="modal-profile" hide-footer size="lg" title="Обратная связь">
      <div class="modal-profile">
        <b-form @submit.prevent="onSubmit" @reset="onReset">
          <b-form-group id="input-group-2" label="Имя" label-for="input-2">
            <b-form-input
                id="input-2"
                v-model="feedbackForm.email"
                placeholder="Email"
                required
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Текст обращения" label-for="input-2">
            <b-form-textarea
                id="input-2"
                v-model="feedbackForm.text"
                placeholder="Текст"
                required
            ></b-form-textarea>
          </b-form-group>

          <div class="row mt-5">
            <div class="col-auto">
              <b-button type="submit" variant="success">Отправить</b-button>
            </div>
            <div class="col-auto">
              <b-button @click="close()" type="button" variant="outline-dark">Отменить</b-button>
            </div>
          </div>
        </b-form>
      </div>
    </b-modal>
    <a href="/" class="logo"></a>
    <div class="top-menu">
      <a href="/courses">Курсы</a>
      <a href="/partners">Обучение от наших партнеров</a>
      <a href="/#opportunities">Возможности</a>
      <a href="/news">Блог</a>
      <a href="/faq">FAQ</a>
      <a href="/contact">Контакты</a>
      <auth/>
    </div>
  </section>
</template>

<script>
import Auth from "./Auth";
import {TokenService} from "../services/token.service";
export default {
  name: "HeadComponent",
  components: {Auth},
  data() {
    return {
      feedbackForm: {},
      user: null
    }
  },
  mounted() {
    this.user = TokenService.getUser()
    this.feedbackForm.email = this.user
  },
  methods: {
    showFeedback() {
      this.$refs['feedback'].show()
    },
    close() {
      this.$refs['feedback'].hide()
    },
    onSubmit() {

    },
    logout() {
      TokenService.clearUser()
      TokenService.removeToken()
      this.user = null
    },
    onReset() {
      this.feedbackForm = {}
    }
  }
}
</script>

<style scoped>

</style>